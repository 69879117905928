import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import AgeGate2 from "../../components/agegate"
import Footer from "../../components/Sunsets2023/WinterFooter"
import { Sessions } from "../../components/Sunsets2023/Sessions"
import { Promotions } from "../../components/Sunsets2023/Promotions"
import AgeGateBoolean from "../../components/AgeGate/AgegateBoolean"

const SessionsPage = ({location}) => {
  const [events, setEvents] = useState([])
  const [age, setAge] = useState(true)

  useEffect(() => {
    let local = localStorage.getItem('age') || sessionStorage.getItem('age')
    if (local) setAge(false)
  }, [])

    useEffect(() => {
        window.location.replace("/sunsets")
    }, [])


  // useEffect(() => {
  //   const places = data?.allSunset2023?.nodes.filter(elem => {
  //     return elem.visible
  //   })
  //   setEvents(places)
  // }, [])

  return !age ? (
    <div>
      <SEO title="SUNSETS SESSIONS 2023" />
      <Sessions eventos={events} />
      <Promotions />
      <Footer />
    </div>
  ) : (
    <AgeGateBoolean location={location} setAge={setAge} />
  )
}

export default SessionsPage

// export const query = graphql`
//   query MainSunset2023Query {
//     allSunset2023 {
//       nodes {
//         date
//         eventName
//         id
//         img
//         img_mobile
//         index
//         link
//         visible
//       }
//     }
//   }
// `
