import React from "react"
import facebook from "../../images/Facebook.png"
import insta from "../../images/Instagram.png"
import styles from "./winter-footer.module.scss"
import { Link } from "gatsby"

import basesSunset from "../../../static/winter-sunsets/BBCC_Corona_Invierno_Sunsets.pdf"
import basesWinter from "../../../static/winter-sunsets/BBCC_Corona_Invierno_Winter.pdf"

const WinterFooter = () => (
  <div className={styles.footer}>
    <div className={styles.containersocialmedia}>
      <Link to="https://www.facebook.com/cervezacorona">
        <img src={facebook} alt="" className={styles.tamanoredes} />
      </Link>
      <Link to="https://instagram.com/corona_argentina?igshid=oxhoy01vemn0">
        <img src={insta} alt="" className={styles.tamanoredes} />
      </Link>
    </div>

    <div className={styles.footerLinksContainer}>
      <div className={styles.footerLinksRow}>
        <a href={"/TyCCervezaCorona.pdf"} download target="blank">
          <h6 className={styles.texttermandcond}>TÉRMINOS DE USO</h6>
        </a>
      </div>
      <h6 className={`${styles.texttermandcond} ${styles.secondVerticalBar}`}>
        |
      </h6>
      <div className={styles.footerLinksRow}>
        <a href={"/PP_AO.pdf"} download target="blank">
          <h6 className={styles.texttermandcond}>POLÍTICAS DE PRIVACIDAD</h6>
        </a>
        <h6 className={`${styles.texttermandcond} ${styles.secondVerticalBar}`}>
          |
        </h6>
      </div>
      <div className={styles.footerLinksRow}>
        <a href={basesSunset} download target="blank">
          <h6 className={styles.texttermandcond}>BASES Y CONDICIONES SUNSET</h6>
        </a>
        <h6 className={`${styles.texttermandcond} ${styles.secondVerticalBar}`}>
          |
        </h6>
      </div>
      <div className={styles.footerLinksRow}>
        <a
          href={
            "https://www.cerveceriaymalteriaquilmes.com/sustentabilidad-consumo-responsable-anterior/"
          }
          target="blank"
        >
          <h6 className={styles.texttermandcond}>consumo responsable</h6>
        </a>
      </div>
    </div>

    <div>
      <p className={styles.textfooter}>
        No se quede conectado si comparte el dispositivo con menores. <br />
        BEBER CON MODERACIÓN. PROHIBIDA SU VENTA A MENORES DE 18 AÑOS. NO
        COMPARTA EL CONTENIDO CON MENORES.
        <br />
        @2021 Corona - Buenos Aires - Argentina. Todos los derechos reservados.
      </p>
    </div>
  </div>
)

export default WinterFooter
