import React from "react"
import Div100vh from "react-div-100vh"
import styles from "./promotions.module.scss"
import logo_tada from "../../../images/sunsets2023/logo_tada.png"

const Promotions = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        
        <h1>
          DISFRUTÁ DEL SUNSET 
        </h1>
        <h2> con una  Corona  bien fría CON hasta </h2>
        <span>
          {" "}
          <h2>35% OFF</h2>{" "}
        </span>
        {/* <button>
          {" "} */}
          <a
            href="https://www.tada.com.ar/catalogo/grupo/corona?source_caller=ui&id=7&screen=Browse&pid=external-corona&shortlink=Ecorona&type=BRAND&af_adset=corona&deep_link_value=external-corona&c=external-corona"
            alt="tada"
            target="_blank"
            rel="noreferrer"
          >
            COMPRAR
          </a>
        {/* </button> */}

        <img src={logo_tada} alt=""/>
      </div>
    </div>
  )
}

export default Promotions
