import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styles from "./sessions.module.scss"
import logo from "../../../images/Logo Page.png"
import arrowleft from "../../../images/winter2022/arrowleft.svg"
import { IsMobileDevice, setupTabletListener } from "../../../utils"
import { SliderSession, SliderSessionMobile } from "../../Slider_sessions"
import swiperStlyes from "../../../styles/page_casaCorona.module.scss"

const Sessions = ({ eventos, backgrounds, location }) => {
  
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {   
    
    setIsMobile(IsMobileDevice())
  }, [])

  const [isTablet, setIsTablet] = useState(false);
  useEffect(() => {
    // Initial check and setup listener
    setupTabletListener(setIsTablet);

    // Clean up the listener on component unmount
    return () => setupTabletListener(setIsTablet);
 }, [isTablet]);
  
  const [mounted, setMounted] = useState(false)
  const [queryEvent, setQueryEvent] = useState(null)
  useEffect(() => {
    if (!mounted){
      return setMounted(true)
    }
    if (!queryEvent) {
      const queryParams = new URLSearchParams(location.search)
      const event = queryParams.get("event")
      // console.log("event:", event)
      setQueryEvent(event)
    }

    if (queryEvent) {
      // Find the element with the matching id
      // console.log("there is an event, queryEvent:", queryEvent)
      const element = document.getElementById(queryEvent)
      // console.log("the element is:", element)

      if (element) {
        // Scroll to the element
        // console.log("scrolling to element...");
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
        }, 250);
      }
    }
  }, [mounted, queryEvent])

 

 const backgroundbloque1 = backgrounds[0].node
//  console.log("bg bloque 1:", backgroundbloque1);

  return (
    <div 
      className={styles.container} 
      style={{ backgroundImage: `url(${backgroundbloque1.img})` }}
    >
      <div className={styles.title}>
        {/* <p className={styles.back} onClick={()=>navigate("/sunsets")} > VOLVER </p> */}
        <span onClick={() => navigate("/")}>
          {" "}
          <img src={arrowleft} /> {isMobile ? "" : "volver"}
        </span>
        <img
          src={logo}
          alt="corona logo"
          className={styles.logo}
          onClick={() => navigate("/")}
        />
        <h1>Disfrutá de nuestros <div className={styles.yellowText}>Sunsets Sessions</div> en diferentes puntos del país</h1>
        {/*<h2 className={styles.next}>Sunsets Sessions</h2>*/}
      </div>
      {isMobile ? (
        <div className={swiperStlyes.swiper}>
          <SliderSessionMobile data={eventos} sunset isTablet={isTablet}/>
        </div>
      ) : (
        <div
          className={swiperStlyes.swiperDesktop}
          style={{
            width: "100%",
          }}
        >
          <SliderSession data={eventos} sunset isTablet={isTablet}/>
        </div>
      )}

      {/*    // : (*/}
      {/*    // <>*/}
      {/*    //   <h1 className={styles.next}>Road to Sunsets Sessions</h1>*/}
      {/*    //   {videoUrls.map(url => {*/}
      {/*    //       return <div className={`${styles.videoContainer}`}>*/}
      {/*    //           <ReactPlayer*/}
      {/*    //               url={url}*/}
      {/*    //               frameborder="0"*/}
      {/*    //               // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"*/}
      {/*    //               width="80%"*/}
      {/*    //               height="80%"*/}
      {/*    //               controls*/}
      {/*    //               muted*/}
      {/*    //               playbackRate={1}*/}
      {/*    //               progressInterval={1000}*/}
      {/*    //               onContextMenu={e => e.preventDefault()}*/}
      {/*    //               config={{*/}
      {/*    //                   file: {*/}
      {/*    //                       attributes: {*/}
      {/*    //                           playsInline: true,*/}
      {/*    //                           controlsList: "nodownload",*/}
      {/*    //                           disableRemotePlayback: true,*/}
      {/*    //                           disablePictureInPicture: true,*/}
      {/*    //                       },*/}
      {/*    //                   },*/}
      {/*    //               }}*/}
      {/*    //               // onStart={() => {*/}
      {/*    //               //     window.dataLayer.push({*/}
      {/*    //               //         'event' : 'GAEvent',*/}
      {/*    //               //         'event_category': 'Video',*/}
      {/*    //               //         'event_action': `Play`,*/}
      {/*    //               //         'event_label': `Momento magico`,*/}
      {/*    //               //         'interaction': 'True',*/}
      {/*    //               //     });*/}
      {/*    //               // }}*/}
      {/*    //               // onEnded={() => {*/}
      {/*    //               //     window.dataLayer.push({*/}
      {/*    //               //         'event' : 'GAEvent',*/}
      {/*    //               //         'event_category': 'Video',*/}
      {/*    //               //         'event_action': `Completed`,*/}
      {/*    //               //         'event_label': `Corona Sunsets`,*/}
      {/*    //               //         'interaction': 'True',*/}
      {/*    //               //     });*/}
      {/*    //               // }}*/}
      {/*    //           />*/}
      {/*    //       </div>*/}
      {/*    //   })*/}
      {/*    // </>*/}
      {/*)}*/}
    </div>
  )
}

export default Sessions
